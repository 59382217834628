import React from "react";
import { Link } from "gatsby";

const CtaLink = ({label, to, title, fontSize}) => {

  return (
    <div style={{
      backgroundColor : "#eed7c5",
      padding : "1rem",
      position : "absolute",
      top: "3rem",
      left: "50%",
      transform: "translateX(-50%)",
      width: "max-content"
    }}>
      <h5 color="black" style={{
        color : "black",
        fontWeight: "bold",
        maxWidth : "200px"
      }}>
        {title}
      </h5>
      <Link
        className="button"
        style={{
          backgroundColor: '#a35940',
          color: 'white',
          maxWidth: '200px',
          height : "70px",
          width: '100%', // Limiter à la largeur parent
          // overflow: 'hidden', // Gérer l'excès de contenu
          textOverflow: 'ellipsis', // Ajouter des "..."
          whiteSpace: 'pre-wrap', // Autoriser les sauts de ligne
          wordWrap: 'break-word', // Forcer les mots longs à se casser
          wordBreak: 'break-word',
          fontSize : fontSize || "16px",
          textAlign : "left"
        }}
        to={to}
      >
        {label}
      </Link>
    </div>
  )
}


export default CtaLink
