import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from "gatsby";

import Layout from '../components/layout/Layout'
import HomeTitle from "../components/home/HomeTitle";
import AcceuilCTA from "../components/home/AcceuilCTA";
import { useIsMobile } from "../hooks/useIsMobile";

export const IndexPageTemplate = ({}) => {


  return (
    <div>
      <div className="is-flex is-justify-content-center content container"
           style={{position: "center", justifyContent: "center", alignItems: "center", marginBottom: 0}}
      >

        <AcceuilCTA/>
      </div>

      <div className="is-flex is-justify-content-center container">
        <HomeTitle/>
      </div>
    </div >
  )
}

IndexPageTemplate.propTypes = {
  imageAcceuil: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  image0: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        imageAcceuil={frontmatter.imageAcceuil}
        image0={frontmatter.image0}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image0 {
          alt
          image {
            childImageSharp {
              gatsbyImageData(width: 1280, quality: 92, layout: CONSTRAINED)
            }
          }
          maxWidth
        }
        intro {
          blurbs {  
            imageBlurb {
              childImageSharp {
                  gatsbyImageData(width: 440, quality: 92, layout: CONSTRAINED)
              }
            }
            text
            description
            url
          }
        }
      }
    }
  }`



