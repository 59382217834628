import React from "react";
import { useIsMobile } from "../../hooks/useIsMobile";
import CtaLink from "./CtaLink";

const AcceuilCTA = () => {


  const {isMobile,isTablet } = useIsMobile()

  return (
    <div style={{
      color: "white",
      marginBottom: "2rem",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    }}>
      <div className="content">
        <div className="columns">
          <div className="column is-12">
            <div className="columns">
              <div className="column is-4 is-mobile" style={{position: "relative"}}>

                <img src={"../../img/v2/Japeropeinturegalerie1-min-crop.JPG"} alt={"Image forma"}
                     style={{height : "auto", width: "100%", objectFit: "cover"}}
                />

                <CtaLink title={"Peindre de la céramique"} label={"Réserver un créneau de peinture"}
                         to={"/cafe-ceramique"}/>
              </div>
              <div className="column is-4 is-mobile" style={{position: "relative"}}>

                <img src={"../../img/v2/mettez_mes_mains_dans_la_terre.jpg"} alt={"Image forma"}
                    style={{height : "auto", width: "100%", objectFit: "cover"}}
                />

                <CtaLink title={"Mettre les mains dans la terre"} label={"S'inscrire à un atelier modelage"}
                         to={"/ateliers-modelage"}/>
              </div>
              <div className="column is-4 is-mobile" style={{position: "relative"}}>

                <img src={"../../img/v2/offres_un_moment_créatif.jpg"} alt={"Image forma"}
                style={{height : "auto", width: "100%", objectFit: "cover"}}
                />

                <CtaLink title={"Offrir un moment créatif"} label={"Acheter un bon cadeau"}
                         to={"/ateliers/test-carte-cadeau/"}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default AcceuilCTA
